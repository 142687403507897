<template>
  <div :class="$themeName() + ' container'">
    <router-view> </router-view>
    <PageFooter />
  </div>
</template>

<script>
import appConfig from "@/app.config";

import Toast from "primevue/toast";
import PageFooter from "./components/page-footer";
import Main from "./views/layouts/main.vue";
import { useDark, useToggle } from "@vueuse/core";

//import('@/assets/scss/theme.scss');

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  components: { Toast, PageFooter, Main },
  setup() {
    const isDark = useDark();
    const toggleDark = useToggle(isDark);

    console.log(isDark.value);

    return {
      toggleDark,
      isDark,
    };
  },

  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
    },
  },
};
</script>

<style></style>
