import { createWebHistory, createRouter } from "vue-router";
//import routes from './routes';


 const routes = [
  {
    path: '/home/:subsidiary/:loanid/:type',
    name: 'Home',
    component: () => import('../views/pages/otp-prompt'),
  },

  {
    path: '/terms/:subsidiary/:loanid/:type',
    name: 'terms-and-conditions',
    component: () => import('../views/pages/terms'),
  },

  {
    path: '/getdocument/:documentId/:loanId',
    name: 'get-epsq-document',
    component: () => import('../views/pages/get-document'),
  },
  {
    path: '/health',
    name: 'health-check',
    component: () => import('../views/pages/health'),
  },
  {

    path: '/consent/:leadId',
    name: 'consent-form',
    component:() => import('../views/pages/consent/index'),
  },
  {

    path: '/thank-you',
    name: 'thank-you-form',
    component:() => import('../views/pages/thank-you/index'),
  }, 

 ];

 const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition;
    }
    return {
        x: 0,
        y: 0,
    };
},
});

// router.beforeEach(async (to, from) => {
//   if (!store.state.isAuthenticated && to.name !== 'Home' && to.name !== 'get-epsq-document' && to.name !== 'health-check') {
//     return { name: 'Home' }
//   }
// })

// export default router;


// const router = new VueRouter({
//     routes,
//     // Use the HTML5 history API (i.e. normal-looking routes)
//     // instead of routes with hashes (e.g. example.com/#/about).
//     // This may require some server configuration in production:
//     // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
//     scrollBehavior(to, from, savedPosition) {
//         if (savedPosition) {
//             return savedPosition
//         } else {
//             return {
//                 x: 0,
//                 y: 0
//             }
//         }
//     },
// })

export default router