import { createApp, h } from 'vue';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'boxicons';

// element-plus
import 'element-plus/theme-chalk/index.css';


import axios from 'axios'
import VueAxios from 'vue-axios'

import { useVuelidate } from '@vuelidate/core'


import App from '@/App.vue';
import router from "@/router";
import store from '@/state/store'
import DomainDetector from './plugins/domainDetector';
import PrimeVue from 'primevue/config';

import VueSignaturePad from 'vue-signature-pad';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import '@/assets/tailwind.css';
import '@/assets/scss/app.scss';




import BootstrapVue from 'bootstrap-vue-3';
// import { BForm} from 'bootstrap-vue-3';
import BootstrapVue3Wrappers from 'bootstrap-vue-3-wrappers'

//import { VueFinalModal } from 'vue-final-modal'







/* add icons to the library */

library.add(fas);
library.add(far);

const app = createApp({
    render: () => h(App),
});
app.use(DomainDetector);
app.use(router)
app.use(store)
app.use(VueSignaturePad);
app.use(useVuelidate);
app.component('fa', FontAwesomeIcon);
app.mount("#app");


app.use(VueAxios, axios)
app.use(BootstrapVue3Wrappers);
//app.use(IconsPlugin);

app.use(PrimeVue);

app.use(BootstrapVue);

//app.component('VueFinalModal', VueFinalModal)